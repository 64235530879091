const getDefaultState = () => {
  return {
    unitSection: {
      selected: {
        id_properti_unit: null
      }
    },
    bayarSection: {
      selected: null
    },
    hargaSection: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_UNIT_SECTION (state, data) {
      state.unitSection = data
    },
    SET_BAYAR_SECTION (state, data) {
      state.bayarSection = data
    },
    SET_HARGA_SECTION (state, data) {
      state.hargaSection = data
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
