import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/propertiUnitD'

export default {
  show (idPropertiUnitD) {
    return httpClient.get(`${endpoint}/${idPropertiUnitD}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAllByUnit (idPropertiUnit) {
    return httpClient.get(`${endpoint}/allByUnit/${idPropertiUnit}`)
  }
}
