<template>
  <div>
    <vs-popup title="Edit Cara bayar" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{ 'opacity-50 pointer-events-none': isLoadingInitData }">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Pilih Cara Bayar *</label>
            <vs-select autocomplete v-model="data.id_cara_bayar" class="w-full">
              <vs-select-item class="vs-select-small" text="Pilih Cara Bayar"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in cara_bayar" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-6/12 w-full mt-5">
            <label class="ml-1 text-xs">Harga Min *</label>
            <v-money class="w-full" v-model="data.harga_min"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full mt-5">
            <label class="ml-1 text-xs">Harga Max *</label>
            <v-money class="w-full" v-model="data.harga_max"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Hpp Tanah</label>
            <v-money class="w-full" v-model="data.hpp_tanah" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Hpp Bangunan</label>
            <v-money class="w-full" v-model="data.hpp_bangunan" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Total Hpp</label>
            <v-money class="w-full" :value="data.hpp_tanah + data.hpp_bangunan" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Harga Standar Tanah *</label>
            <v-money class="w-full" v-model="data.harga_standar_tanah"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Harga Standar Bangunan *</label>
            <v-money class="w-full" v-model="data.harga_standar_bangunan"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full mt-5">
            <label class="ml-1 text-xs">Total Harga Standar</label>
            <v-money class="w-full" :value="data.harga_standar_tanah + data.harga_standar_bangunan" disabled/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'
import VMoney from '@/views/components/v-money/VMoney'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import PropertiUnitDRepository from '@/repositories/master/properti-unit-d-repository'

export default {
  name: 'BayarEdit',
  props: {
    isActive: { required: true, type: Boolean },
    initData: { required: true, type: Object }
  },
  components: {
    ValidationErrors,
    VMoney
  },
  computed: {
    filterStaf () {
      return { only_have_user: true }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isLoadingInitData: false,
      errors: null,
      cara_bayar: [],
      modalUser: {
        active: false
      },
      data: {}
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        if (this.isFirstActive) {
          this.getCaraBayar()
          this.getInitData()
          this.isFirstActive = false
        } else {
          this.getInitData()
          this.errors = null
        }
      }
    }
  },
  methods: {
    getInitData () {
      this.getPropertiUnitD()
    },

    getPropertiUnitD () {
      this.isLoadingInitData = true

      const idPropertiUnitD = this.initData.id
      PropertiUnitDRepository.show(idPropertiUnitD)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    getCaraBayar () {
      const group1 = 'KONTRAK'
      CaraBayarRepository.getAllByGroup(group1)
        .then(response => {
          this.cara_bayar = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        id: this.data.id,
        id_properti_unit: this.data.id_properti_unit,
        id_cara_bayar: this.data.id_cara_bayar,
        harga_standar_tanah: this.data.harga_standar_tanah,
        harga_standar_bangunan: this.data.harga_standar_bangunan,
        harga_min: this.data.harga_min,
        harga_max: this.data.harga_max
      }
      PropertiUnitDRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
