<template>
  <div :class="{'pointer-events-none opacity-50': !selectedUnit.id_properti_unit}">
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Cara Bayar" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div class="email__actions flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex w-full justify-between items-center py-2">
          <div class="flex items-center px-2">
            <span v-if="loading" class="text-right text-xs">Loading...</span>
            <span v-else class="text-right text-xs font-semibold">Cara Bayar</span>
          </div>
          <div class="flex items-center space-x-3">
            <feather-icon @click="showModalAdd" icon="PlusIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <component v-if="selectedUnit.id_properti_unit" :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div class="w-full h-full flex items-center" v-if="listCaraBayar.length === 0">
          <span class="w-full text-center">Tidak Ada Data</span>
        </div>
        <div v-for="(item, index) in listCaraBayar" :key="index" @click.stop="onSelected(item)" class="flex px-3 py-3 space-x-2 w-full justify-between zs-center border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light" :class="{'bg-primary-transparent-10': selected === item}">
          <div class="flex items-center space-x-3">
            <feather-icon icon="DollarSignIcon" svg-classes="h-5 w-5"/>
            <div class="hover:font-medium">
              <p class="text-sm font-semibold">{{ item.nama_cara_bayar }}</p>
              <div class="flex items-center space-x-2">
                <div class="flex-row">
                  <div class="vx-col w-full mt-1">
                    <p class="text-xs">Harga Standar Tanah: {{ item.harga_standar_tanah | idr }}</p>
                  </div>
                  <div class="vx-col w-full mt-1">
                    <p class="text-xs">Harga Standar Bangunan: {{ item.harga_standar_bangunan | idr }}</p>
                  </div>
                  <div class="vx-col w-full mt-1">
                    <p class="text-xs">Harga Min: {{ item.harga_min | idr }}</p>
                  </div>
                  <div class="vx-col w-full mt-1">
                    <p class="text-xs">Harga Max: {{ item.harga_max | idr }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-2 pr-2">
            <feather-icon icon="EditIcon" svg-classes="h-3 w-3" class="hover:text-warning" @click="showModalEdit(item)"/>
            <feather-icon icon="TrashIcon" svg-classes="h-3 w-3" class="hover:text-danger" @click="confirmDelete(item)"/>
          </div>
        </div>
      </component>
    </div>

    <!--modals-->
    <BayarAdd :isActive.sync="modalAdd.active" :selectedUnit="modalAdd.selectedUnit" @success="refresh"/>
    <BayarEdit :isActive.sync="modalEdit.active" :initData="modalEdit.initData" @success="refresh"/>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import BayarAdd from '@/views/pages/master/properti-unit/set-cara-bayar/cara-bayar/BayarAdd'
import BayarEdit from '@/views/pages/master/properti-unit/set-cara-bayar/cara-bayar/BayarEdit'
import PropertiUnitDRepository from '@/repositories/master/properti-unit-d-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'BayarSection',
  components: {
    BayarAdd,
    BayarEdit,
    VuePerfectScrollbar
  },
  computed: {
    listCaraBayar () {
      const items = this.items
      // if search
      const search = this.search ? this.search.toLowerCase() : null
      if (search != null) {
        return _.filter(items, item => {
          return item.nama_cara_bayar.toLowerCase().includes(search)
        })
      }
      return items
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.8
      }
    },
    dataUnitSection () {
      return this.$store.state.master.propertiUnit.unitSection
    },
    selectedUnit () {
      return this.dataUnitSection.selected
    }
  },
  watch: {
    selectedUnit (selected) {
      this.items = []
      this.selected = null
      if (selected.id_properti_unit) {
        this.getInitData()
        this.getSinglePropertiUnit(selected.id_properti_unit)
      }
    },
    selected: {
      deep: true,
      handler (value) {
        const data = { selected: value }
        this.commitDataToStore(data)
      }
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      selected: null,
      items: [],
      modalAdd: {
        selectedUnit: {
          properti_unit: {}
        },
        active: false
      },
      modalEdit: {
        initData: {},
        active: false
      }
    }
  },
  methods: {
    showModalAdd () {
      this.modalAdd.selectedUnit = this.selectedUnit
      this.modalAdd.active = true
    },

    showModalEdit (maker) {
      this.modalEdit.initData = maker
      this.modalEdit.active = true
    },

    getInitData () {
      this.loading = true

      const idPropertiUnit = this.selectedUnit.id_properti_unit
      PropertiUnitDRepository.getAllByUnit(idPropertiUnit)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    getSinglePropertiUnit (idPropertiUnit) {
      this.loading = true

      PropertiUnitRepository.show(idPropertiUnit)
        .then(response => {
          this.selectedUnit.properti_unit = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onSelected (item) {
      this.selected = item
    },

    refresh () {
      this.selected = null
      this.getInitData()
    },

    confirmDelete (item) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(item) }
      })
    },

    delete (item) {
      const idPropertiUnitD = item.id
      PropertiUnitDRepository.delete(idPropertiUnitD)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
          if (this.selected === item) {
            this.selected = null
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('master/propertiUnit/SET_BAYAR_SECTION', payload)
    })
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
