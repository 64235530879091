<template>
  <div>
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari No Unit" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div class="email__actions flex items-center flex-wrap justify-between border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex w-full justify-between items-center py-2">
          <div class="flex items-center px-2">
            <span v-if="loading" class="text-right text-xs">Loading...</span>
            <span v-else class="text-right text-xs font-semibold">List Properti Unit</span>
          </div>
          <div class="flex items-center space-x-2">
            <vs-select autocomplete v-model="id_proyek" @input="onProyekChanged" class="w-full vs-select-small" style="margin-top: -2.5px; margin-bottom: -2.5px; border: solid #414561 1px;">
              <vs-select-item class="vs-select-small" text="Pilih Proyek"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.kode + ' - ' + it.nama"/>
            </vs-select>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <component :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div class="w-full h-full flex items-center" v-if="!selectedProyek">
          <p class="w-full text-sm text-center">Silahkan pilih <span class="font-semibold">Proyek</span> terlebih dahulu.</p>
        </div>
        <div class="w-full h-full flex items-center" v-if="selectedProyek && listPropertiUnits.length === 0">
          <span class="w-full text-center">Tidak Ada Data</span>
        </div>
        <transition-group name="list-enter-up" class="email__mails" tag="ul" appear>
          <li class="cursor-pointer email__mail-item only-border-bottom select-none" v-for="(item, index) in listPropertiUnits" :key="item.uuid" :id="index" @click="onItemSelected(item)">
            <div class="mail__mail-item sm:px-4 px-2 py-2" :class="{ 'bg-primary-transparent-25': selected.id_properti_unit === item.id }">
              <div class="flex">
                <feather-icon icon="HomeIcon" class="pr-3" svg-classes="h-5 w-5"/>
                <div class="flex w-full justify-between items-start">
                  <div class="mail__details">
                    <h6 class="font-medium text-sm">{{ item.no_unit }}</h6>
                    <span class="text-xs bg-ema">{{ item.kode_properti_tipe }}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </component>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'UnitSection',
  components: {
    VuePerfectScrollbar
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    listPropertiUnits () {
      const items = this.propertiUnit
      // if search
      const search = this.search ? this.search.toLowerCase() : null
      if (search != null) {
        return _.filter(items, item => {
          return item.no_unit.toLowerCase().includes(search) || item.kode_properti_tipe.toLowerCase().includes(search)
        })
      }
      return items
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.8
      }
    }
  },
  watch: {
    selected: {
      deep: true,
      handler (value) {
        const data = { selected: value }
        this.commitDataToStore(data)
      }
    }
  },
  data () {
    return {
      id_proyek: null,
      selectedProyek: false,
      search: null,
      loading: false,
      proyeks: [],
      propertiUnit: [],
      selected: {
        id_properti_unit: null
      }
    }
  },
  methods: {
    onProyekChanged () {
      this.selectedProyek = true
      this.refresh()
    },

    getInitData () {
      this.loading = true

      const idProyek = this.id_proyek
      PropertiUnitRepository.getAllByProyek(idProyek)
        .then(response => {
          this.propertiUnit = response.data.data.map(item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    getProyek () {
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onItemSelected (item) {
      this.selected.id_properti_unit = item.id
    },

    refresh () {
      this.getInitData()
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('master/propertiUnit/SET_UNIT_SECTION', payload)
    })
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
