<template>
  <div id="email-app" class="flex relative overflow-hidden">
    <!----------- UNIT ------------->
    <div class="w-full sm:w-6/12 d-theme-dark-bg">
      <UnitSection/>
    </div>

    <!----------- CARA BAYAR ------------->
    <div class="w-full sm:w-6/12 d-theme-dark-bg">
      <BayarSection/>
    </div>

<!--    &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; INPUT HARGA -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--    <div class="w-full sm:w-4/12 d-theme-dark-bg">-->
<!--      <FormHargaSection/>-->
<!--    </div>-->
  </div>
</template>

<script>
import BayarSection from '@/views/pages/master/properti-unit/set-cara-bayar/cara-bayar/BayarSection'
// import FormHargaSection from '@/views/pages/master/properti-unit/set-cara-bayar/form-harga/FormHargaSection'
import UnitSection from '@/views/pages/master/properti-unit/set-cara-bayar/unit/UnitSection'
import modulePropertiUnitApprovals from '@/store/modules/master/properti-unit/properti-unit.store'

export default {
  name: 'SystemSetCaraBayar',
  components: {
    BayarSection,
    // FormHargaSection,
    UnitSection
  },
  beforeCreate () {
    this.$store.registerModule(['master', 'propertiUnit'], modulePropertiUnitApprovals)
  },
  beforeDestroy () {
    this.$store.commit('master/propertiUnit/RESET_STATE')
    this.$store.unregisterModule(['master', 'propertiUnit'])
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
